<template>
  <div class="steps">
    <div class="item" :class="{active: step >= i + 1}" v-for="(a, i) in steps" :key="i">
      <div class="no" v-if="typeof a === 'string'">{{ i + 1 }}</div>
      <div class="no" v-else>
        <el-image :src="'./assets/step/' + a.icon[step >= i + 1 ? 'active' : 'default']"></el-image>
      </div>
      <div class="text">{{ a.text || a }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Steps",
  props: ['steps','step']
}
</script>

<style scoped lang="scss">
.steps{
  display: flex;
  justify-content: space-between;
  position: relative;
  &::after{
    content: '';
    width: 100%;
    height: .5px;
    background: #E5E5E5;
    position: absolute;
    top: 15px;
    left: 0;
  }
  .item{
    text-align: center;
    background: #FFFFFF;
    position: relative;
    z-index: 1;
    padding: 0 10px;
    .no{
      width: 30px;
      height: 30px;
      background: #cccccc;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      margin: 0 auto;
    }
    .text{
      margin-top: 10px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #cccccc;
      line-height: 17px;
    }
    &.active{
      .no{
        background: $base-color;
      }
      .text{
        color: #222222;
      }
    }
  }
}
</style>
