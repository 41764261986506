<template>
  <!-- 设置银行卡 -->
  <div class="works">
    <LeftMenu current="income" />
    <div class="main">
      <el-row>
        <el-col :span="12">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/income">我的收益</el-breadcrumb-item>
            <el-breadcrumb-item to="/accountandsecurity">安全认证</el-breadcrumb-item>
            <el-breadcrumb-item>设置银行卡</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="12" style="text-align: right"
          ><el-link type="primary" @click="$router.back()">
            &lt;返回</el-link
          ></el-col
        >
      </el-row>
      <el-divider></el-divider>
        <steps
        :step="step"
        :steps="[
          { text: '安全验证', icon: { active: '07.png', default: '07.png' } },
          { text: '填写银行卡', icon: { active: '08.png', default: '09.png' } },
          { text: '提交成功', icon: { active:'10.png', default:'11.png' } },
        ]"
      ></steps>
      <div class="centent">
          <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import steps from '../components/Steps'
import LeftMenu from '../components/LeftMenu.vue'
export default {
  components: {
    LeftMenu,steps
  },
  created () {
  },
  data() {
      return {
         step:1
      }
  },
  watch:{
      '$route.name'(newval) {
          if(newval=='Setcard'){
              this.step =2
          }else if(newval=='Subbank'){
               this.step =3
          }else{
               this.step =1
          }

      }
  }

}
</script>

<style scoped lang="scss">
@import "../styles/initialization.scss";
@import "../styles/works.scss";
.el-divider {
  margin-top: 12px;
  margin-bottom:60px;
}
.centent{
    box-sizing: border-box;
    padding-top:60px;

}
</style>
